import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export enum PlainModals {
  CONNECTORS_REMOVE_INTEGRATION_MODAL = 'connectors_remove_integration_modal',
  PDF_PREVIEW_MODAL = 'pdf_preview_modal',
  SEND_REPORT_MODAL = 'send_report_modal',
  INVITE_USER_MODAL = 'invite_user_modal',
  REMOVE_USER_MODAL = 'remove_user_modal',
  GROUP_TEAM_MODAL = 'group_team_modal',
  ASSETS_RUNS_MODAL = 'assets_runs_modal',
  PACKAGE_REPOSITORIES_MODAL = 'package_repositories_modal',
  PACKAGE_ADVISORY_MODAL = 'package_advisory_modal',
  IDENTITIES_EMAILS_MODAL = 'identities_emails_modal',
  TEAMS_MEMBERS_MODAL = 'teams_members_modal',
  MISSING_USERS_MODAL = 'missing_users_modal',
  REPOSITORY_IMPORTERS_MODAL = 'repository_importers_modal',
  ADVISORIES_MODAL = 'advisories_modal',
  CONTRIBUTORS_REPUTATION_MODAL = 'contributors_reputation_modal',
  IDENTITIES_DETAILS_RISK_REASON_MODAL = 'identities_details_risk_reason_modal',
  IDENTITIES_DETAILS_DETAILS_JSON_MODAL = 'identities_details_details_json_modal',
  CONFIRMATION_NOTES_MODAL = 'confirmation_notes_modal',
  NOTES_VIEWER_MODAL = 'notes_viewer_modal',
  VIEW_INFO_MODAL = 'view_info_modal',
  TRIVY_SCAN_MODAL = 'trivy-scan-modal',
  PR_SECRETS_MODAL = 'pr-secrets-modal',
}

export interface StatusModal {
  status?: 'error' | 'warning' | 'success';
  title: string;
  description?: string;
  isOpen: boolean;
}

export interface ModalsState {
  status_modal: StatusModal;
  [PlainModals.CONNECTORS_REMOVE_INTEGRATION_MODAL]: boolean;
  [PlainModals.PDF_PREVIEW_MODAL]: boolean;
  [PlainModals.SEND_REPORT_MODAL]: boolean;
  [PlainModals.INVITE_USER_MODAL]: boolean;
  [PlainModals.REMOVE_USER_MODAL]: boolean;
  [PlainModals.GROUP_TEAM_MODAL]: boolean;
  [PlainModals.ASSETS_RUNS_MODAL]: boolean;
  [PlainModals.PACKAGE_ADVISORY_MODAL]: boolean;
  [PlainModals.PACKAGE_REPOSITORIES_MODAL]: boolean;
  [PlainModals.ADVISORIES_MODAL]: boolean;
  [PlainModals.IDENTITIES_EMAILS_MODAL]: boolean;
  [PlainModals.TEAMS_MEMBERS_MODAL]: boolean;
  [PlainModals.MISSING_USERS_MODAL]: boolean;
  [PlainModals.REPOSITORY_IMPORTERS_MODAL]: boolean;
  [PlainModals.CONTRIBUTORS_REPUTATION_MODAL]: boolean;
  [PlainModals.IDENTITIES_DETAILS_RISK_REASON_MODAL]: boolean;
  [PlainModals.IDENTITIES_DETAILS_DETAILS_JSON_MODAL]: boolean;
  [PlainModals.CONFIRMATION_NOTES_MODAL]: boolean;
  [PlainModals.NOTES_VIEWER_MODAL]: boolean;
  [PlainModals.VIEW_INFO_MODAL]: boolean;
  [PlainModals.TRIVY_SCAN_MODAL]: boolean;
  [PlainModals.PR_SECRETS_MODAL]: boolean;
  metaData: Partial<{ [key: string]: string }>;
}

const initialState: ModalsState = {
  status_modal: {
    isOpen: false,
    description: '',
    title: '',
  },
  [PlainModals.CONNECTORS_REMOVE_INTEGRATION_MODAL]: false,
  [PlainModals.PDF_PREVIEW_MODAL]: false,
  [PlainModals.SEND_REPORT_MODAL]: false,
  [PlainModals.INVITE_USER_MODAL]: false,
  [PlainModals.REMOVE_USER_MODAL]: false,
  [PlainModals.GROUP_TEAM_MODAL]: false,
  [PlainModals.ASSETS_RUNS_MODAL]: false,
  [PlainModals.PACKAGE_REPOSITORIES_MODAL]: false,
  [PlainModals.IDENTITIES_EMAILS_MODAL]: false,
  [PlainModals.PACKAGE_ADVISORY_MODAL]: false,
  [PlainModals.ADVISORIES_MODAL]: false,
  [PlainModals.TEAMS_MEMBERS_MODAL]: false,
  [PlainModals.MISSING_USERS_MODAL]: false,
  [PlainModals.REPOSITORY_IMPORTERS_MODAL]: false,
  [PlainModals.CONTRIBUTORS_REPUTATION_MODAL]: false,
  [PlainModals.IDENTITIES_DETAILS_RISK_REASON_MODAL]: false,
  [PlainModals.IDENTITIES_DETAILS_DETAILS_JSON_MODAL]: false,
  [PlainModals.CONFIRMATION_NOTES_MODAL]: false,
  [PlainModals.NOTES_VIEWER_MODAL]: false,
  [PlainModals.VIEW_INFO_MODAL]: false,
  [PlainModals.TRIVY_SCAN_MODAL]: false,
  [PlainModals.PR_SECRETS_MODAL]: false,
  metaData: {},
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModalByNameAction(
      state: ModalsState,
      action: PayloadAction<{
        type: PlainModals;
        metaData?: Partial<{ [key: string]: any }>;
      }>,
    ) {
      state[action.payload.type] = true;
      if (action.payload.metaData) {
        state.metaData = action.payload.metaData;
      }
    },
    closeModalByNameAction(
      state: ModalsState,
      action: PayloadAction<PlainModals>,
    ) {
      state[action.payload] = false;
      state.metaData = {};
    },
    setStatusModalAction(
      state: ModalsState,
      action: PayloadAction<StatusModal>,
    ) {
      state.status_modal = action.payload;
    },
  },
});

export const { openModalByNameAction, closeModalByNameAction } =
  modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
